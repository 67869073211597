import { createI18n } from 'vue-i18n';

import appConfig from '@/configs/app';
import Debug from '@/utils/Debug';

import en from '@/locales/en';
import de from '@/locales/de';

const locales: {[p: string]: {}} = {en, de};

const i18n = createI18n({
  legacy: false,
  locale: getStartingLocale(),
  fallbackLocale: appConfig.i18n.fallbackLocale,
  messages: locales,

  // turn of html warnings in console
  // https://stackoverflow.com/a/73513649/6498658
  warnHtmlMessage: Debug.isEnabled(),
  warnHtmlInMessage: Debug.isEnabled() ? 'on' : 'off',
});

/**
 * get starting locale from browser or settings
 * @return {string}
 */
export function getStartingLocale(): string {
  const browserLocale: string|undefined = getBrowserLocale();
  return appConfig.i18n.localeAutoSelect && browserLocale ? browserLocale : appConfig.i18n.locale;
}

/**
 * get available locale identifier
 * @return {array}
 */
export function getAvailableLocales(): string[] {
  return Object.keys(locales);
}

/**
 * get best matching locale from browser data
 * @return {string|undefined}
 */
export function getBrowserLocale(): string|undefined {
  const availableLocales: string[] = getAvailableLocales();

  // get from languages array
  if (navigator.languages !== undefined && navigator.languages) {
    for (const value of navigator.languages) {
      for (const locale of availableLocales) {
        if (value.startsWith(locale)) {
          return locale;
        }
      }
    }
  }

  // get from language string
  if (navigator.language !== undefined && navigator.language) {
    for (const locale of availableLocales) {
      if (navigator.language.startsWith(locale)) {
        return locale;
      }
    }
  }

  return undefined;
}

/**
 * get current locale from i18n instance
 * @return {string}
 */
export function getCurrentLocale(): string {
  return i18n.global.locale.value;
}

/**
 * change current locale on i18n instance
 * @param {string} locale
 * @return {void}
 */
export function setLocale(locale: string): void {
  i18n.global.locale.value = locale;
}

// export translation functions
// renamed to $t and $tm, to be consistent with names in vue templates
const { t, tm } = i18n.global;
export const $t = t;
export const $tm = tm;

// export plugin instance
export default i18n;
