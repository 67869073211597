export default {
  META: {
    TITLE: 'midtones.de - Home'
  },
  INTRO: 'Mein Name ist Robert Schmidl - selbstständiger Software Entwickler aus Berlin. Ich entwickle Software für Sie, berate Sie in Projekten allgemein und kann Sie beim Management von Softwareprojekten unterstützen. Ich bevorzuge pragmatische Lösungen, bin Kaffee Snob und Hobby Fotograf.',
  HEADLINE: 'Was ist midtones?',
  BODY: [
    'midtones ist der Name, unter dem ich seit über 15 Jahren Selbstständigkeit meine Kunden in vielen verschiedenen Bereichen unterstütze.',
    'In der Fotografie sind die midtones - die Mitteltöne - die Werte eines Bildes, die einen Ausgleich zwischen Höhen und Tiefen, den Extremen, schaffen. Gut balancierte Mitteltöne geben einem Bild Tiefe und Qualität.',
    'Der Name vereint eine ausgewogene, ausgeglichene Herangehensweise an Kundenprojekte mit meinem Hobby, der Fotografie.',
    'Mein Anspruch ist, dass ich meinen Kunden nicht nur die Umsetzung von Software verkaufe, sondern sie auch darin beraten kann, die Lösungen für sie zu finden, die am besten zu ihnen passen - selbst wenn diese sich von den Vorstellungen unterscheiden, mit denen sie an mich herangetreten sind.',
  ],
  LINKS: {
    LINKEDIN: 'Profil auf LinkedIn',
    GITHUB: 'Profile auf Github',
    FLICKR: 'Profil auf Flickr',
    INSTAGRAM: 'Profil auf Instagram',
    GITLAB: 'Gitlab Installation'
  },
  BODY_LINKS: {
    SERVICES: 'Kann ich Sie in Ihren Projekten unterstützen?',
    PROJECTS: 'Eine Auswahl vergangener Projekte.'
  }
};
