export default {
  TITLE: {
    PREFIX: '.:: ',
    SUFFIX: ' ::.',
    DEFAULT: 'midtones.de',
  },
  DESCRIPTION: {
    DEFAULT: 'Freelance Web Development, Software Consulting and Project Management working out of Berlin.',
  },
};
