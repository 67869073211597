export default {
  META: {
    TITLE: 'midtones.de - Home'
  },
  INTRO: 'My name is Robert Schmidl - freelance software developer from Berlin. I develop software for you, advise you on projects in general and can support you in the management of software projects. I prefer pragmatic solutions, I am a coffee snob and a hobby photographer.',
  HEADLINE: 'What is midtones?',
  BODY: [
    'midtones is the name under which I have been supporting my clients in many different areas for over 15 years of self-employment.',
    'In photography, the midtones are the values of an image that create a balance between highs and lows, the extremes. Well-balanced midtones give an image depth and quality.',
    'The name combines a balanced, even-handed approach to customer projects with my hobby, photography.',
    'My aim is not only to sell my customers the implementation of software, but also to advise them on finding the solutions that best suit them - even if these differ from the ideas they approached me with.',
  ],
  LINKS: {
    LINKEDIN: 'Find me on LinkedIn',
    GITHUB: 'Profile on Github',
    FLICKR: 'Profile on Flickr',
    INSTAGRAM: 'Profile on Instagram',
    GITLAB: 'Gitlab Installation'
  },
  BODY_LINKS: {
    SERVICES: 'Find out how I can help you with your projects.',
    PROJECTS: 'Want to see a selection of past projects?'
  }
};
