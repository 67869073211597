export default {
  CUSTOMER: {
    NAME: 'Customer',
    WEBSITE: 'Website Customer',
  },
  PROJECT: {
    TITLE: 'Project Title',
    START: 'Project Start',
    END: 'Project End',
    LINK: 'Link to project',
  },
  DESCRIPTION: {
    HEADING: 'Description',
  },
  ROLES: {
    HEADING: 'Roles I fulfilled in this project',
  },
  TECHNOLOGIES: {
    HEADING: 'Technologies used in this project'
  },
  FOOTER: {
    DISCLAIMER: 'Disclaimer: The presentation of the project information has, as far as possible, been agreed with those responsible for the project. This was not possible in all cases. If you wish to object to the use of individual content, please contact me - I will remove the content in question immediately.'
  }
};
