import { nextTick } from 'vue';
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, Router, RouteRecordNormalized } from 'vue-router';
import { getCurrentLocale } from '@/plugins/i18n';

import routes from '@/configs/routes';
import MetaData from '@/services/MetaData';
import Debug from '@/utils/Debug';

const router: Router = createRouter({
  history: createWebHistory('/'),
  scrollBehavior(): {left: number, top: number} {
    return { left: 0, top: 0 };
  },
  routes: routes,
});

// update page meta-data after routing
router.afterEach((to: RouteLocationNormalized): void => {
  // wait for dom changes to be applied before changing meta-data
  nextTick().then((): void => {
    MetaData.setMetaData(to.meta);

    // track via matomo with correct meta data
    if (MetaData !== null && MetaData.getTitle() !== null) {
      window._paq.push(['setDocumentTitle', MetaData.getTitle()]);
    }
    window._paq.push(['setCustomVariable', 1, 'Locale', getCurrentLocale(), 'page']);
    window._paq.push(['trackPageView']);
  });
});

export default router;

/**
 * default error handler for page routing
 * will suppress errors of getting redirected to the same page as its currently on
 * @param {object} err
 * @return {void}
 */
export const RouterSamePageErrorHandler = (err: Error): void => {
  const searchMessage: string = 'Avoided redundant navigation to current location';

  // ignore error regarding navigating to the same page as are already on
  if (err.name !== 'NavigationDuplicated' && !err.message.includes(searchMessage)) {
    // print any other errors to the console via debug utility
    Debug.toConsole(err);
  }
};
