export default {
  META: {
    TITLE: 'midtones.de - Imprint'
  },
  HEADING: 'Imprint',
  LEGAL: [
    'Robert Schmidl',
    'midtones.de',
    'Rotherstraße 28',
    '10245 Berlin',
  ],
  CONTACT_HEADING: 'Contact details',
  CONTACT: [
    'Phone: +49 (0) 30 20 666 666',
    'E-Mail: robert@midtones.de',
  ],
  ARBITRATION_HEADING: 'Consumer dispute resolution/universal arbitration centre',
  ARBITRATION: [
    'I am not required to participate in dispute resolution proceedings before a consumer arbitration board.',
  ]
};
