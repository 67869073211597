export default {
  META: {
    TITLE: 'midtones.de - Impressum'
  },
  HEADING: 'Impressum',
  LEGAL: [
    'Robert Schmidl',
    'midtones.de',
    'Rotherstraße 28',
    '10245 Berlin',
  ],
  CONTACT_HEADING: 'Kontakt',
  CONTACT: [
    'Telefon: +49 (0) 30 20 666 666',
    'E-Mail: robert@midtones.de',
  ],
  ARBITRATION_HEADING: 'Verbraucherstreitbeilegung/Universalschlichtungsstelle',
  ARBITRATION: [
    'Ich bin nicht verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.',
  ]
};
