export default {
  META: {
    TITLE: 'midtones.de - Page Not Found'
  },
  HEADING: '404 - Page not Found',
  BODY: [
    'Irure amet dolor laboris dolor nisi dolore ut dolor in laboris nostrud elit ullamco ad.',
    'Irure amet dolor laboris dolor nisi dolore ut dolor in laboris nostrud elit ullamco ad.',
  ]
};
