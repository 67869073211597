export default {
  LABELS: {
    NAME: 'Ihr Name',
    EMAIL: 'Ihre E-Mail Adresse',
    SUBJECT: 'Beschreiben Sie kurz worum es geht',
    MESSAGE: 'Ihre Nachricht',
    SEND: 'Abschicken'
  },
  PLACEHOLDERS: {
    NAME: 'Name',
    EMAIL: 'E-Mail',
    SUBJECT: 'Betreff',
    MESSAGE: 'Nachricht',
  },
  NOTIFICATION: {
    SUCCESS: {
      HEADLINE: 'Nachricht versandt',
      BODY: 'Ihre Nachricht wurde verschickt. Ich werde mich so schnell wie möglich mit Ihnen in Verbindung setzen. Vielen Dank.'
    },
    ERROR: {
      HEADLINE: 'Nachricht konnte nicht verschickt werden',
      BODY: 'Es gab einen Fehler beim Versuch, Ihre Nachricht zu verschicken. Bitte schicken Sie mir eine direkte E-Mail an die Adresse auf der Homepage oder probieren Sie es später noch einmal.'
    }
  }
};
