export default {
  META: {
    TITLE: 'midtones.de - Seite nicht gefunden'
  },
  HEADING: '404 - Seite nicht gefunden',
  BODY: [
    'Irure amet dolor laboris dolor nisi dolore ut dolor in laboris nostrud elit ullamco ad.',
    'Irure amet dolor laboris dolor nisi dolore ut dolor in laboris nostrud elit ullamco ad.',
  ]
};
