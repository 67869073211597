export default {
  META: {
    TITLE: 'midtones.de - Datenschutzerklärung'
  },
  HEADING: 'Privacy policy',
  PRIVACY_HEADING: 'Data protection',
  PRIVACY: {
    MISC: {
      HEADING: 'General information',
      BODY: [
        'The following information provides a simple overview of what happens to your personal data when you visit this website. Personal data is any data that can be used to identify you personally. Detailed information on the subject of data protection can be found in our data protection declaration listed below this text.',
      ],
    },
    COLLECTION: {
      HEADING: 'Data collection on this website',
      BODY: [
        'Who is responsible for data collection on this website?',
        'The following information provides a simple overview of what happens to your personal data when you visit this website. Personal data is any data that can be used to identify you personally. Detailed information on the subject of data protection can be found in our data protection declaration listed below this text.',
        'How do we collect your data?',
        'On the one hand, your data is collected when you provide it to us. This may, for example, be data that you enter in a contact form.',
        'Other data is collected automatically or with your consent by our IT systems when you visit the website. This is primarily technical data (e.g. internet browser, operating system or time of page view). This data is collected automatically as soon as you enter this website.',
        'What do we use your data for?',
        'Some of the data is collected to ensure that the website is provided without errors. Other data may be used to analyse your user behaviour.',
        'What rights do you have regarding your data?',
        'You have the right to receive information about the origin, recipient and purpose of your stored personal data free of charge at any time. You also have the right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right to request the restriction of the processing of your personal data under certain circumstances. Furthermore, you have the right to lodge a complaint with the competent supervisory authority.',
        'You can contact us at any time if you have further questions on the subject of data protection.'
      ],
    },
    TRACKING: {
      HEADING: 'Analysis tools and tools from third-party providers',
      BODY: [
        'When you visit this website, your surfing behaviour may be statistically evaluated. This is mainly done with so-called analysis programs.',
        'Detailed information on these analysis programs can be found in the following privacy policy.',
      ],
    },
  },
  HOSTING_HEADING: 'Hosting',
  HOSTING: {
    BODY: [
      'We host the content of our website with the following provider:',
      'Hetzner',
      'The provider is Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (hereinafter referred to as Hetzner).',
      'Details can be found in Hetzner\'s privacy policy: https://www.hetzner.com/de/rechtliches/datenschutz',
      'The use of Hetzner is based on Art. 6 para. 1 lit. f GDPR. We have a legitimate interest in displaying our website as reliably as possible. If a corresponding consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a GDPR and § 25 para. 1 TDDDG, insofar as the consent includes the storage of cookies or access to information in the user\'s terminal device (e.g. device fingerprinting) within the meaning of the TDDDG. Consent can be revoked at any time.',
    ],
  },
  MISC_HEADING: 'General notes and mandatory information',
  MISC: {
    PRIVACY: {
      HEADING: 'Data protection',
      BODY: [
        'The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this privacy policy.',
        'When you use this website, various personal data is collected. Personal data is data that can be used to identify you personally. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this is done.',
        'We would like to point out that data transmission over the Internet (e.g. when communicating by e-mail) may be subject to security vulnerabilities. Complete protection of data against access by third parties is not possible.',
      ]
    },
    PERSON: {
      HEADING: 'Information on the responsible body',
      BODY: [
        'The controller responsible for data processing on this website is',
        '',
        'Robert Schmidl',
        'midtones.de',
        'Rotherstraße 28',
        '10245 Berlin',
        '',
        'Phone: +49 (0) 30 20 666 666',
        'E-Mail: robert@midtones.de',
        '',
        'The controller is the natural or legal person who alone or jointly with others determines the purposes and means of the processing of personal data (e.g. names, email addresses, etc.).',
      ]
    },
    STORAGE: {
      HEADING: 'Storage duration',
      BODY: [
        'Unless a more specific storage period has been specified in this privacy policy, your personal data will remain with us until the purpose for data processing no longer applies. If you assert a justified request for deletion or revoke your consent to data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g. retention periods under tax or commercial law); in the latter case, the deletion will take place after these reasons no longer apply.',
      ]
    },
    LEGAL: {
      HEADING: 'General information on the legal basis for data processing on this website',
      BODY: [
        'If you have consented to data processing, we process your personal data on the basis of Art. 6 para. 1 lit. a GDPR or Art. 9 para. 2 lit. a GDPR, insofar as special categories of data are processed in accordance with Art. 9 para. 1 GDPR. In the event of express consent to the transfer of personal data to third countries, data processing is also carried out on the basis of Art. 49 para. 1 lit. a GDPR. If you have consented to the storage of cookies or access to information in your end device (e.g. via device fingerprinting), the data processing is also carried out on the basis of Section 25 (1) TDDDG. Consent can be revoked at any time. If your data is required for the fulfilment of a contract or for the implementation of pre-contractual measures, we process your data on the basis of Art. 6 para. 1 lit. b GDPR. Furthermore, we process your data if this is necessary to fulfil a legal obligation on the basis of Art. 6 para. 1 lit. c GDPR. Data processing may also be carried out on the basis of our legitimate interest in accordance with Art. 6 para. 1 lit. f GDPR. Information on the relevant legal bases in each individual case is provided in the following paragraphs of this privacy policy.',
      ]
    },
    THIRDPARTY: {
      HEADING: 'Recipients of personal data',
      BODY: [
        'As part of our business activities, we work together with various external organisations. In some cases, it is also necessary to transfer personal data to these external organisations. We only pass on personal data to external bodies if this is necessary for the fulfilment of a contract, if we are legally obliged to do so (e.g. passing on data to tax authorities), if we have a legitimate interest in the transfer in accordance with Art. 6 para. 1 lit. f GDPR or if another legal basis permits the transfer of data. When using processors, we only pass on our customers\' personal data on the basis of a valid contract for order processing. In the case of joint processing, a joint processing agreement is concluded.',
      ]
    },
    REVOCATION: {
      HEADING: 'Revocation of your consent to data processing',
      BODY: [
        'Many data processing operations are only possible with your express consent. You can withdraw your consent at any time. The legality of the data processing carried out until the revocation remains unaffected by the revocation.',
      ]
    },
    REVOCATION_RIGHT: {
      HEADING: 'Right to object to the collection of data in special cases (Art. 21 GDPR)',
      BODY: [
        'If the data processing is carried out on the basis of A. 6 para. 1 lit. E or F GDPR, you have the right to object to the processing of your personal data at any time for reasons arising from your particular situation; this also applies to profiling based on these provisions. the respective legal basis on which processing is based can be found in this data protection declaration. If you object, we will no longer process your personal data concerned unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms or the processing serves the establishment, exercise or defence of legal claims (objection pursuant to Art. 21 (1) GDPR).',
      ]
    },
    COMPLAINT: {
      HEADING: 'Right to lodge a complaint with the competent supervisory authority',
      BODY: [
        'In the event of violations of the GDPR, data subjects have the right to lodge a complaint with a supervisory authority, in particular in the Member State of their habitual residence, place of work or place of the alleged violation. The right to lodge a complaint is without prejudice to any other administrative or judicial remedies.',
      ]
    },
    PORTABILITY: {
      HEADING: 'Right to data portability',
      BODY: [
        'You have the right to have data that we process automatically on the basis of your consent or in fulfilment of a contract handed over to you or to a third party in a commonly used, machine-readable format. If you request the direct transfer of the data to another controller, this will only take place if it is technically feasible.',
      ]
    },
    DELETION: {
      HEADING: 'Information, correction and deletion',
      BODY: [
        'Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, its origin and recipient and the purpose of the data processing and, if necessary, a right to correction or deletion of this data. You can contact us at any time if you have further questions on the subject of personal data.',
      ]
    },
    LIMIT: {
      HEADING: 'Right to restrict processing',
      BODY: [
        'You have the right to request the restriction of the processing of your personal data. You can contact us at any time to do this. The right to restriction of processing exists in the following cases:',
        'If you dispute the accuracy of your personal data stored by us, we generally need time to check this. For the duration of the review, you have the right to request that the processing of your personal data be restricted.',
        'If the processing of your personal data was/is unlawful, you can request the restriction of data processing instead of erasure.',
        'If we no longer need your personal data, but you need it for the exercise, defence or assertion of legal claims, you have the right to request the restriction of the processing of your personal data instead of its erasure.',
        'If you have lodged an objection in accordance with Art. 21 (1) GDPR, a balance must be struck between your interests and ours. As long as it has not yet been determined whose interests prevail, you have the right to request the restriction of the processing of your personal data.',
        'If you have restricted the processing of your personal data, this data - apart from its storage - may only be processed with your consent or for the establishment, exercise or defence of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the European Union or of a Member State.',
      ]
    },
    CRYPTO: {
      HEADING: 'TLS encryption',
      BODY: [
        'This site uses TLS encryption for security reasons and to protect the transmission of confidential content, such as orders or enquiries that you send to us as the site operator. You can recognise an encrypted connection by the fact that the address line of the browser changes from ‘http://’ to ‘https://’ and by the lock symbol in your browser line.',
        'If TLS encryption is activated, the data you transmit to us cannot be read by third parties.',
      ]
    }
  },
  COLLECTION_HEADING: 'Data collection on this website',
  COLLECTION: {
    HEADING: 'Contact form',
    BODY: [
      'If you send us enquiries via the contact form, your details from the enquiry form, including the contact details you provide there, will be stored by us for the purpose of processing the enquiry and in the event of follow-up questions. We will not pass on this data without your consent.',
      'This data is processed on the basis of Art. 6 para. 1 lit. b GDPR if your enquiry is related to the fulfilment of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the enquiries addressed to us (Art. 6 para. 1 lit. f GDPR) or on your consent (Art. 6 para. 1 lit. a GDPR) if this has been requested; consent can be revoked at any time.',
      'We will retain the data you provide on the contact form until you request its deletion, revoke your consent for its storage, or the purpose for its storage no longer pertains (e.g. after fulfilling your request). Mandatory statutory provisions - in particular retention periods - remain unaffected.',
    ]
  },
  ANALYSIS_HEADING: 'Analysis tools',
  ANALYSIS: {
    MATOMO: {
      HEADING: 'Matomo',
      BODY: [
        'This website uses the open source web analysis service Matomo.',
        'This analysis tool is used on the basis of Art. 6 para. 1 lit. f GDPR. The website operator has a legitimate interest in analysing user behaviour in order to optimise both its website and its advertising. If a corresponding consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a GDPR and § 25 para. 1 TDDDG, insofar as the consent includes the storage of cookies or access to information in the user\'s terminal device (e.g. device fingerprinting) within the meaning of the TDDDG. Consent can be revoked at any time.',
        'Hosting',
        'We host Matomo exclusively on our own servers so that all analysis data remains with us and is not passed on.',
      ],
      OPTOUT_HEADING: 'Matomo Opt-Out',
      OPTOUT_BODY: 'You can prevent this website from collecting and analysing the actions you take. This protects your privacy, but also prevents the owner from learning from your actions and creating a better experience for you and other users.',
      CURRENTLY_OPTED_OUT: 'You are currently unsubscribed from tracking.',
      CURRENTLY_NOT_OPTED_OUT: 'You are not currently unsubscribed from tracking.',
      OPTOUT: 'I no longer want to be tracked.',
      OPTIN: 'I agree to the tracking of my data.',
    }
  }
};
