export default {
  META: {
    TITLE: 'midtones.de - Services'
  },
  INTRO: 'Mit über 15 Jahren Erfahrung als Freiberufler biete ich Dienstleistungen in den Bereichen Entwicklung, Projektmanagement und Beratung an. Meine langjährige Expertise und erfolgreiche Zusammenarbeit mit zahlreichen Kunden in diesen drei Bereichen ermöglichen professionelle und effiziente Lösungen für Ihre Projekte. Auf dieser Seite finden Sie Informationen zu jedem meiner Angebote. Wenn Sie einen Bereich finden, in dem ich Sie in Ihren Projekten unterstützen kann, dann schreiben Sie mir.',
  DEVELOPMENT: {
    HEADLINE: 'Entwicklung',
    BULLETPOINTS: [
      'Programmiersprachen: Ruby, Javascript, PHP, Objective-C, Swift',
      'Frameworks: Ruby on Rails, Sinatra, VueJS, Symfony',
      'Entwicklung: Web Apps, Wordpress Plugins, iOS Apps',
      'Hosting: Ubuntu, AWS, Digital Ocean',
      'Infrastruktur: Docker, Ansible',
      'APIs: REST, GraphQL, SOAP',
      'Tests: RSpec, vitest, XCTest',
      'Editor: Sublime Text, NeoVim',
    ],
    DESCRIPTION: [
      'Als Full-Stack-Entwickler biete ich pragmatische und umfassende Lösungen für Ihre Projekte. Meine Expertise erstreckt sich über das gesamte Spektrum der Webentwicklung, von der Umsetzung benutzerfreundlicher Frontends bis hin zur Implementierung leistungsstarker Backend-Systeme. Ich arbeite mit einer Vielzahl von Programmiersprachen und Frameworks und kann mich schnell in neue Technologien einarbeiten, die ggf. für Ihr spezifisches Projekt erforderlich sind.',
      'Am Anfang jedes Entwicklungs-Auftrags steht ein umfassender Abgleich mit meinen Kunden, in dem wir gemeinsam herausfinden, welche Lösungen angebracht und für sie nützlich sind. Ich verfolge einen pragmatischen Ansatz, der die für Sie geeignete Lösung sucht, nicht diejenige, die ich am teuersten verkaufen kann.',
    ]
  },
  PROJECTMANAGEMENT: {
    HEADLINE: 'Projektmanagement',
    BULLETPOINTS: [
      'Koordinierung von Entwicklerteams',
      'Roadmap Entwicklung',
      'Milestone Planung',
      'Kommunikation mit Stakeholdern',
      'Integration von externen Ressourcen',
      'Sprintplanung',
      'Release Management',
      'Requirements Management',
    ],
    DESCRIPTION: [
      'Mein Ansatz im Projektmanagement baut darauf, eine nahtlose und zielorientierte Kommunikation zwischen internen und externen Ressourcen zu gewährleisten. Ich fungiere als Brücke zwischen verschiedenen Abteilungen und Stakeholdern, um ein effizientes Projektumfeld zu schaffen. Besonders wertvoll ist meine Fähigkeit, komplexe technische Konzepte für nicht-technische Entscheidungsträger verständlich zu machen. Dies ermöglicht eine klare Kommunikation und fundierte Entscheidungsfindung auf allen Ebenen, was besonders wichtig ist, wenn Kunden keinen technischen Hintergrund haben.',
      'Mein Ziel ist es, nicht nur die technischen Aspekte eines Projekts zu steuern, sondern auch den Geschäftswert für meine Kunden zu maximieren. Dazu gehört auch, Risiken und Probleme möglichst frühzeitig zu identifizieren und zur Sprache zu bringen. Durch regelmäßige Updates, klare Meilensteine und flexible Anpassungen an sich ändernde Anforderungen sorge ich dafür, dass Ihre Projektziele erreicht werden.',
    ]
  },
  CONSULTING: {
    HEADLINE: 'Beratung',
    BULLETPOINTS: [
      'Code Reviews',
      'System Analyse',
      'Analyse und Formulierung von Anforderungen',
      'Formulierung von Stellenbeschreibungen',
      'Prozessentwicklung',
      'Workflow Analyse und Management',
      'Entwicklung von Proof-of-Concept Studien',
      'Unterstützung bei Kommunikation',
    ],
    DESCRIPTION: [
      'Auf Basis klarer Kommunikation und mit einem umfassenden Verständnis der Anforderungen meiner Kunden, setze ich meine Expertise ein, um Sie umfassend beraten zu können. Ich kann für Sie komplexe geschäftliche Herausforderungen in technologische Anforderungen übersetzen und optimale Lösungsansätze zusammen mit Ihnen durch sorgfältige Analyse und offenen Dialog finden. Mein Ziel ist es, Sie in die Lage zu versetzen, fundierte Entscheidungen zu treffen, indem ich Ihnen die relevanten Technologien, Trends und Best Practices verständlich erkläre. So können Sie selbstbewusst die Lösungen auswählen, die Ihrem Unternehmen den größten Mehrwert bieten.',
      'Als externer Experte biete ich einen objektiven Blick auf Ihre Systeme oder eine bestehende Codebase. Gerne unterstütze ich Sie bei Proof-Of-Concept Studien, bewerte Ihren aktuellen Systemzustand oder identifiziere Optimierungsmöglichkeiten. Ich kann Ihnen gezielte Empfehlungen für Verbesserungen oder Refactoring geben und unterstütze Sie bei der Analyse Ihrer Systeme in Sachen Wartbarkeit, Skalierung und Qualität Ihrer Software. Dies ermöglicht Ihnen, bessere strategische Entscheidungen in Sachen Technologie zu treffen, die Ihrem Unternehmen helfen.',
    ]
  },
  BODY_LINKS: {
    CONTACT: 'Kontaktieren Sie mich gerne, wenn ich Sie mit den beschriebenen Leistungen unterstützen kann.',
    PROJECTS: 'Eine Auswahl vergangener Projekte.'
  }
};
