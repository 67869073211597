export default {
  META: {
    TITLE: 'midtones.de - Projekte'
  },
  INTRO: 'Über die Jahre habe ich eine breite Auswahl an Projekten für meine Kunden umgesetzt. Hier finden Sie einen Überblick über eine Auswahl dieser Projekte, die zeigt, wie ich in der Vergangenheit Kunden in den Bereichen Entwicklung, Projektmanagement und Beratung unterstützt habe.',
  DESCRIPTION: [
    'Jedes Projekt hatte seine eigenen Herausforderungen und Lösungen. Wenn Sie mehr über ein bestimmtes Projekt erfahren möchten, klicken Sie einfach darauf.',
    ' ',
    ' ',
  ],
  BODY_LINKS: {
    SERVICES: 'Erfahren Sie, in welchen Bereichen ich Sie in Ihren Projekten unterstützen kann.',
    CONTACT: 'Kontaktieren Sie mich gerne, wenn ich Sie in ähnlichen Projekten unterstützen kann.',
  }

};
