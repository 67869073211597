export default {
  TITLE: {
    PREFIX: '.:: ',
    SUFFIX: ' ::.',
    DEFAULT: 'midtones.de',
  },
  DESCRIPTION: {
    DEFAULT: 'Freelancer für Web-Entwicklung, Software Consulting und Projektmanagement aus Berlin.',
  },
};
