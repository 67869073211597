import { RouteMeta } from 'vue-router';
import { $t, $tm } from '@/plugins/i18n';

import appConfig from '@/configs/app';

import Debug from '@/utils/Debug';

class MetaData {
  /**
   * page title element
   * @var {HtmlElement}
   * @private
   */
  private titleElement: HTMLElement|null = null;

  /**
   * page description element
   * @var {HtmlElement}
   * @private
   */
  private descriptionElement: HTMLElement|null = null;

  /**
   * open-graph title tag element
   * @var {HtmlElement}
   * @private
   */
  private ogTitleElement: HTMLElement|null = null;

  /**
   * open-graph description tag element
   * @var {HtmlElement}
   * @private
   */
  private ogDescriptionElement: HTMLElement|null = null;

  /**
   * open-graph image tag element
   * @var {HtmlElement}
   * @private
   */
  private ogImageElement: HTMLElement|null = null;

  /**
   * set all information by route meta-data object
   * @param {RouteMeta} metaData
   * @return {void}
   */
  public setMetaData(metaData: RouteMeta): void {
    this.setTitle(metaData.title as string);
    this.setDescription(metaData.description as string);
    this.setOgTitle(metaData.ogTitle as string);
    this.setOgDescription(metaData.ogDescription as string);
    this.setOgImage(metaData.ogImage as string);
  }

  /**
   * reset page meta-data to default values
   * @return {void}
   */
  public resetMetaData(): void {
    this.setTitle(null);
    this.setDescription(null);
    this.setOgTitle(null);
    this.setOgDescription(null);
    this.setOgImage(null);
  }

  /**
   * set page title
   * @param {string} title
   * @return void
   */
  public setTitle(title: string|null|undefined): void {
    if (!this.titleElement) {
      this.titleElement = document.querySelector('head title');
    }

    if (!title || title === null) {
      title = appConfig.metaData.defaultTitle;
    }

    if (this.titleElement) {
      this.titleElement.textContent = `${$t(appConfig.metaData.titlePrefix)}${$t(title)}${$t(appConfig.metaData.titleSuffix)}`;
    }
  }

  /**
   * get current page title
   * @return string
   */
  public getTitle(): string|null {
    if (!this.titleElement) {
      this.titleElement = document.querySelector('head title');
    }
    if (this.titleElement !== null) {
      return this.titleElement.textContent;
    }
    else {
      return null;
    }

  }

  /**
   * set page description
   * @param {string} description
   * @return void
   */
  public setDescription(description: string|null|undefined): void {
    if (!this.descriptionElement) {
      this.descriptionElement = document.querySelector('head meta[name="description"]');
    }

    if (!description) {
      description = appConfig.metaData.defaultDescription;
    }

    if (this.descriptionElement) {
      this.descriptionElement.setAttribute('content', $tm(description));
    }
  }

  /**
   * set open-graph title tag
   * @param {string} ogTitle
   * @return void
   */
  public setOgTitle(ogTitle: string|null|undefined): void {
    if (!this.ogTitleElement) {
      this.ogTitleElement = document.querySelector('head meta[property="og:title"]');
    }

    if (!ogTitle) {
      ogTitle = appConfig.metaData.defaultOgTitle;
    }

    if (this.ogTitleElement) {
      this.ogTitleElement.setAttribute('content', `${$t(appConfig.metaData.titlePrefix)}${$t(ogTitle)}${$t(appConfig.metaData.titleSuffix)}`);
    }
  }

  /**
   * set open-graph description tag
   * @param {string} ogDescription
   * @return void
   */
  public setOgDescription(ogDescription: string|null|undefined): void {
    if (!this.ogDescriptionElement) {
      this.ogDescriptionElement = document.querySelector('head meta[property="og:description"]');
    }

    if (!ogDescription) {
      ogDescription = appConfig.metaData.defaultOgDescription;
    }

    if (this.ogDescriptionElement) {
      this.ogDescriptionElement.setAttribute('content', $t(ogDescription));
    }
  }

  /**
   * set open-graph image tag
   * @param {string} ogImage
   * @param {boolean} [addBaseUrl]
   * @return void
   */
  public setOgImage(ogImage: string|null|undefined, addBaseUrl: boolean = true): void {

    if (!this.ogImageElement) {
      this.ogImageElement = document.querySelector('head meta[property="og:image"]');
    }

    if (!ogImage) {
      ogImage = appConfig.metaData.defaultOgImage;
    }

    if (addBaseUrl) {

      ogImage = `${appConfig.metaData.imageBaseUrl}${ogImage}`;
    }

    if (this.ogImageElement) {
      this.ogImageElement.setAttribute('content', ogImage);
    }
  }
}

export default new MetaData();
