export default {
  META: {
    TITLE: 'midtones.de - Services'
  },
  INTRO: 'With over 15 years of experience as a freelancer, I offer highly qualified services in the areas of development, project management, and consulting. My long-standing expertise and successful collaboration with numerous clients across all three of those service areas ensure professional and efficient solutions for your projects. On this page, you\'ll find detailed information about each of my offered services. Discover how my comprehensive know-how in technical support, management, and expert consulting can drive your projects to success.',
  DEVELOPMENT: {
    HEADLINE: 'Development',
    BULLETPOINTS: [
      'Languages: Ruby, Javascript, PHP, Objective-C, Swift',
      'Frameworks: Ruby on Rails, Sinatra, VueJS, Symfony',
      'Development: Web Apps, Wordpress Plugins, iOS Apps',
      'Hosting: Ubuntu, AWS, Digital Ocean',
      'Infrastructure: Docker, Ansible',
      'APIs: REST, GraphQL, SOAP',
      'Tests: RSpec, vitest, XCTest',
      'Editor: Sublime Text, NeoVim',
    ],
    DESCRIPTION: [
      'As a full-stack developer, I offer pragmatic and comprehensive solutions for your projects. My expertise covers the entire spectrum of web development, from the realisation of user-friendly frontends to the implementation of powerful backend systems. I work with a variety of programming languages and frameworks and can quickly familiarise myself with new technologies that may be required for your specific project.',
      'Every development assignment starts with a comprehensive consultation with my customers, in which we work together to find out which solutions are appropriate and useful for them. I take a pragmatic approach that seeks the most suitable solution for you, not the one I can sell at the highest price.',
    ]
  },
  PROJECTMANAGEMENT: {
    HEADLINE: 'Project Management',
    BULLETPOINTS: [
      'Coordination of development teams',
      'Roadmap development',
      'Milestone planning',
      'Communication with stakeholders',
      'Integration of external resources',
      'Sprint planning',
      'Release Management',
      'Requirements Management',
    ],
    DESCRIPTION: [
      'My approach to project management is based on ensuring seamless and goal-orientated communication between internal and external resources. I act as a bridge between different departments and stakeholders to create an efficient project environment. Particularly valuable is my ability to make complex technical concepts understandable to non-technical decision makers. This enables clear communication and informed decision making at all levels, which is particularly important when clients do not have a technical background.',
      'My aim is not only to manage the technical aspects of a project, but also to maximise the business value for my clients. This includes identifying and addressing risks and problems as early as possible. Through regular updates, clear milestones and flexible adjustments to changing requirements, I ensure that your project goals are achieved.',
    ]
  },
  CONSULTING: {
    HEADLINE: 'Consulting',
    BULLETPOINTS: [
      'Code Reviews',
      'System analysis',
      'Analysing and formulating requirements',
      'Formulation/wording of job descriptions',
      'Process development',
      'Workflow analysis and management',
      'Development of proof-of-concept studies',
      'Support with communication',
    ],
    DESCRIPTION: [
      'Based on clear communication and a comprehensive understanding of my clients\' requirements, I use my expertise to provide you with comprehensive advice. I can translate complex business challenges into technological requirements for you and find optimal solutions together with you through careful analysis and open dialogue. My aim is to enable you to make informed decisions by explaining the relevant technologies, trends and best practices in an understandable way. This enables you to confidently select the solutions that offer the greatest added value to your organisation.',
      'As an external expert, I offer an objective view of your systems or an existing codebase. I am happy to support you with proof-of-concept studies, evaluate your current system status or identify optimisation opportunities. I can give you specific recommendations for improvements or refactoring and support you in analysing your systems in terms of maintainability, scaling and quality of your software. This enables you to make better strategic technology decisions that will help your organisation.',
    ]
  },
  BODY_LINKS: {
    CONTACT: 'Please feel free to contact me if I can support you with any of the services described.',
    PROJECTS: 'Want to see a selection of past projects?'
  }
};
