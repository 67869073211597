// general
import meta from '@/locales/en/meta';

// components
import navigation from '@/locales/en/components/navigation';
import footer from '@/locales/en/components/footer';
import contactform from '@/locales/en/components/contactform';
import projectmodal from '@/locales/en/components/projectmodal';

// views
import home from '@/locales/en/views/home';
import imprint from '@/locales/en/views/imprint';
import privacy from '@/locales/en/views/privacy';
import projects from '@/locales/en/views/projects';
import services from '@/locales/en/views/services';
import contact from '@/locales/en/views/contact';
import pagenotfound from '@/locales/en/views/pagenotfound';

export default {
  // general
  META: meta,

  // components
  NAVIGATION: navigation,
  FOOTER: footer,
  CONTACTFORM: contactform,
  PROJECTMODAL: projectmodal,

  // views
  HOME: home,
  IMPRINT: imprint,
  PRIVACY: privacy,
  PROJECTS: projects,
  SERVICES: services,
  CONTACT: contact,
  PAGENOTFOUND: pagenotfound,
};
