export default {
  META: {
    TITLE: 'midtones.de - Home'
  },
  INTRO: 'Over the years, I have realised a wide range of projects for my clients. Here you will find an overview of a selection of these projects, which shows how I have supported clients in the past in the areas of development, project management and consulting.',
  DESCRIPTION: [
    'Each project had its own challenges and solutions. If you would like to find out more about a specific project, simply click on it.',
    ' ',
    ' ',
  ],
  BODY_LINKS: {
    SERVICES: 'Find out in which areas I can support you in your projects.',
    CONTACT: 'Feel free to contact me if I can support you in similar projects.',
  }
};
