<template>
  <div class="fixed inset-0 flex justify-center sm:px-8">
    <div class="flex w-full max-w-7xl lg:px-8">
      <div class="w-full bg-white ring-1 ring-zinc-100"></div>
    </div>
  </div>

  <div class="relative flex w-full flex-col">
    <PageHeader />
    <div class="flex-none"></div>
    <main class="flex-auto">
      <router-view :key="$route.fullPath" />
    </main>

    <PageFooter />
  </div>

  <ProjectModal />

</template>



<script lang="ts">
  import { defineComponent } from 'vue';
  import { RouterView } from 'vue-router';

  import PageHeader from '@/components/PageHeader.vue';
  import PageFooter from '@/components/PageFooter.vue';

  import ProjectModal from'@/components/ProjectModal.vue';

  export default defineComponent({
    name: 'App',
    components: {
      RouterView,
      PageHeader,
      PageFooter,
      ProjectModal,
    },
    setup() {
      return {};
    },
    mounted() {
    },
  });
</script>
