<template>
  <footer class="mt-32 flex-none">
    <div class="sm:px-8">
      <div class="mx-auto w-full max-w-7xl lg:px-8">
        <div class="border-t border-zinc-100 pb-16 pt-10">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="flex flex-col items-center justify-between gap-6 sm:flex-row">
                <div class="flex flex-wrap justify-center gap-x-6 gap-y-1 text-sm font-medium text-zinc-800">
                  <router-link to="/imprint" class="transition hover:text-teal-500">
                    {{ $t('FOOTER.IMPRINT') }}
                  </router-link>
                  <router-link to="/privacy" class="transition hover:text-teal-500">
                    {{ $t('FOOTER.PRIVACY') }}
                  </router-link>
                </div>
                  <p class="text-sm text-zinc-400 dark:text-zinc-500">
                    {{ $t('FOOTER.COPYRIGHT') }}
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>


<script lang="ts">
  import { defineComponent} from 'vue';

  export default defineComponent({
    name: 'PageFooter',
    setup() {
      return {};
    },
  });
</script>
