
<template>

  <div
    id="project-modal"
    tabindex="-1"
    class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative w-full max-w-7xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow">
            <!-- Modal header -->
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <h3 class="text-xl font-medium text-gray-900">
                    {{ projectTitle }}
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="project-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                </button>
            </div>
            <!-- Modal body -->
            <div class="p-4 md:p-5 space-y-4">

              <!--  Gallery -->
              <section id="project-gallery">
                <div id="project-carousel" class="relative w-full" data-carousel="slide">
                  <!-- Carousel wrapper -->
                  <div class="relative h-56 overflow-hidden rounded-lg md:h-96">
                    <!-- Items  -->
                    <div
                      class="hidden duration-700 ease-in-out"
                      data-carousel-item
                      v-for="(image, index) in galleryImages"
                      :id="'carousel-item-'+index"
                      :key="index">
                        <img :src="image" class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" :alt="index.toString()">
                    </div>
                  </div>
                  <!-- Slider indicators -->
                  <div class="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
                      <button
                        type="button"
                        class="w-3 h-3 rounded-full"
                        aria-current="true"
                        aria-label="Slide 1"
                        v-for="(image, index) in galleryImages"
                        :key="index"
                        :data-carousel-slide-to="index">
                      </button>
                  </div>
                  <!-- Slider controls -->
                  <button type="button" class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                      <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                          <svg class="w-4 h-4 text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
                          </svg>
                      </span>
                  </button>
                  <button type="button" class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                      <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                          <svg class="w-4 h-4 text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                          </svg>
                      </span>
                  </button>
                </div>
              </section>

              <!--  Details -->
              <section id="project-details">
                <div class="grid grid-cols-1 md:grid-cols-3 mt-4 space-y-3 md:space-y-0">
                  <div class="space-y-3">
                    <p>
                      <span class="font-bold mr-3">
                        {{ $t('PROJECTMODAL.PROJECT.TITLE') }}
                      </span>
                      {{ projectTitle }}
                    </p>
                    <p v-if="project">
                      <span class="font-bold mr-3">
                        {{ $t('PROJECTMODAL.CUSTOMER.NAME') }}
                      </span>
                      {{ project.customerName }}
                    </p>
                    <p v-if="project && project.customerWebsite !== '' && project.customerWebsite !== null">
                      <span class="font-bold mr-3">
                        {{ $t('PROJECTMODAL.CUSTOMER.WEBSITE') }}
                      </span>
                      <a :href="project.customerWebsite" target="_blank" class="text-teal-700">
                        {{ project.customerWebsite }}
                      </a>
                    </p>
                  </div>
                  <div class="space-y-3">
                    <p v-if="project">
                      <span class="font-bold mr-3">
                        {{ $t('PROJECTMODAL.PROJECT.START') }}
                      </span>
                      {{ project.start }}
                    </p>
                    <p v-if="project">
                      <span class="font-bold mr-3">
                        {{ $t('PROJECTMODAL.PROJECT.END') }}
                      </span>
                      {{ project.end }}
                    </p>
                  </div>
                  <div class="space-y-3">
                    <p v-if="project && project.link !== '' && project.link !== null">
                      <span class="font-bold mr-3">
                        {{ $t('PROJECTMODAL.PROJECT.LINK') }}
                      </span>
                      <a :href="project.link" target="_blank" class="text-teal-700">
                        {{ project.link }}
                      </a>
                    </p>
                  </div>
                </div>
              </section>

              <!--  Description -->
              <section id="project-description" class="mt-3 pt-4">
                <h4 class="font-bold">
                  {{ $t('PROJECTMODAL.DESCRIPTION.HEADING') }}
                </h4>
                <p class="text-justify mb-3 mt-3" v-for="(description, index) in project.description" :key="index" v-if="project">
                  {{ description }}
                </p>
              </section>

              <section id="project-roles-tech" class="mt-3 pt-4">
                <div class="grid grid-cols-1 md:grid-cols-2 space-y-4 md:space-y-0">
                  <!--  Roles -->
                  <div>
                    <h4 class="font-bold mb-3">
                      {{ $t('PROJECTMODAL.ROLES.HEADING') }}
                    </h4>



                    <ul class="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg" v-if="project">
                      <li
                        v-for="(role,index) in project.roles"
                        :key="index"
                        class="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg">
                          {{ role }}
                      </li>
                    </ul>
                  </div>

                  <!--  Technologies -->
                  <div>
                    <h4 class="font-bold mb-3">
                      {{ $t('PROJECTMODAL.TECHNOLOGIES.HEADING') }}
                    </h4>

                    <ul class="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg" v-if="project">
                      <li
                        v-for="(tech) in project.technologies"
                        :key="tech.id"
                        class="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg hover:bg-gray-100 hover:text-teal-500 cursor-pointer ">
                          <a
                            :href="tech.link"
                            target="_blank"
                            class="flex"
                            v-if="tech.link !== '' && tech.link !== null"
                            >
                              <img :src="tech.image" class="mr-2 max-h-6" :alt="(tech.shorthand === null ? 'logo' : tech.shorthand)" v-if="tech.image !== '' && tech.image !== null">
                              {{ tech.name }}
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </div>

            <!-- Modal footer -->
            <div class="flex p-4 md:p-5 border-t border-gray-200 rounded-b justify-end">
              <p class="font-light text-sm text-zinc-600">
                {{ $t('PROJECTMODAL.FOOTER.DISCLAIMER') }}
              </p>
            </div>
        </div>
    </div>
  </div>



</template>


<script lang="ts">
  import { defineComponent, PropType, ref } from 'vue';
  import { Modal, Carousel } from 'flowbite';
  import type { ModalOptions, ModalInterface, CarouselInterface, CarouselItem } from 'flowbite';
  import type { InstanceOptions } from 'flowbite';

  import Project from '@/interfaces/Project.i';

  import EventBus from '@/services/EventBus';

  import Debug from '@/utils/Debug';

  export default defineComponent({
    name: 'ProjectModal',
    components: {
      Modal
    },
    setup() {
      const carouselElement = ref<HTMLElement | null>(null);
      const carousel = ref<CarouselInterface | null>(null);

      const project = ref<Project | null>(null)

      return {
        modalElement: ref<HTMLElement|null>(null),
        modalOptions: ref<ModalOptions>({}),
        instanceOptions: ref<InstanceOptions>({}),
        modal: ref<ModalInterface>(),
        project,
        carouselElement,
        carousel,
      };
    },
    watch: {
      project: {
        immediate: true,
        handler(newProject) {
          if (newProject) {
            this.$nextTick(() => {
              this.initializeCarousel();
            });
          }
        }
      }
    },
    async beforeMount(): Promise<void> {
      // register event to switch project data when locale switched
      EventBus.on(EventBus.keys.OPEN_PROJECT_MODAL, (data: any): void => {
        if (data['chosenProject']) {
          this.project = data['chosenProject'];
        }
        this.toggleModal();
      });
    },
    mounted(): void {
      this.modalElement = document.querySelector('#project-modal');
      this.modalOptions = {
          backdrop: 'dynamic',
          placement: 'top-center',
          closable: true,
          onHide: () => {
              //
          },
          onShow: () => {
            if (this.project) {
              window._paq.push(['setDocumentTitle', this.project.title]);
              window._paq.push(['trackPageView']);
            }
          },
          onToggle: () => {
              //
          },
      };
      this.instanceOptions = {
        id: 'project-modal',
      };

      this.modal = new Modal(this.modalElement, this.modalOptions, this.instanceOptions);
      this.carouselElement = document.getElementById('project-carousel');
      this.initializeCarousel();
    },
    computed: {
      /**
       * get the project teaser title
       * @return {string}
       */
      projectTitle(): string {
        if (this.project) {
          if (this.project.title !== null) {
            return this.project.title;
          }
          else {
            return '';
          }
        }
        else {
          return '';
        }
      },
      /**
       * get the project teaser text
       * @return {string}
       */
      projectTeaser(): string {
        if (this.project) {
          if (this.project.teaser !== null) {
            return this.project.teaser;
          }
          else {
            return 'Do excepteur mollit non veniam excepteur cupidatat enim anim minim elit duis.';
          }
        }
        else {
          return 'Do excepteur mollit non veniam excepteur cupidatat enim anim minim elit duis.';
        }
      },
      /**
       * get the project teaser image
       * @return {string}
       */
      galleryImages(): string[] {
        if (this.project) {
          if (this.project.images.length > 0) {
            let _images: string[] = [];
            for (const imageString of this.project.images) {
              if (imageString.startsWith("https://")) {
                _images.push(imageString);
              }
              else {
                if (imageString !== 'logo.jpg')
                _images.push('/images/projects/'+this.project.id+'/'+imageString)
              }
            }
            return _images;
          }
          else {
            return ['','',''];
          }
        }
        else {
          return ['','',''];
        }
      },
      /**
       * get project duration
       * @return {string}
       */
      projectDuration(): string {
        let duration = '';
        if (this.project) {
          if (this.project.start !== null) {
            duration = this.project.start;
            if (this.project.end !== null) {
              duration = this.project.start + ' - ' + this.project.end;
            }
          }
        }
        return duration;
      }
    },
    methods: {
      toggleModal(): void {
        this.modal?.toggle();
      },
      initializeCarousel() {
        if (this.carouselElement) {
          // Destroy existing carousel if it exists
          if (this.carousel) {
            this.carousel.destroy();
          }

          // Initialize new carousel
          const _carouselItems: CarouselItem[] = [];
          let i = 0;
          for (const item of this.carouselElement.querySelectorAll('[data-carousel-item]')) {
            let _element: HTMLElement|null = document.getElementById(item.id);
            if (_element !== null) {
              _carouselItems.push({position: i, el: _element});
              i++;
            }
          }

          if (_carouselItems.length > 0) {
            this.carousel = new Carousel(this.carouselElement, _carouselItems, {
            // Carousel options
            defaultPosition: 0,
            // Add any other options you need
            }, {
              id: 'project-carousel',
              override: true,
            });
          }
        }
      },
    }
  });
</script>

<style scoped>

</style>
