// app imports
import { createApp } from 'vue';
import App from '@/App.vue';

// plugins
import router from '@/plugins/router';
import i18n from '@/plugins/i18n';

// tailwind
import '@/tailwind.css';

export function setupApp() {
  // create app instance
  const app = createApp(App, {
    render: (h: any) => h(App),
  });

  // register plugins
  app.use(router);
  app.use(i18n);

  return app;
}

// Only run this if we're in the browser environment
if (typeof window !== 'undefined') {
  const app = setupApp();

  // mount app in ./index.html
  app.mount('#midtones');
}
