export default {
  CUSTOMER: {
    NAME: 'Kunde',
    WEBSITE: 'Homepage Kunde',
  },
  PROJECT: {
    TITLE: 'Projektname',
    START: 'Projektbeginn',
    END: 'Projektende',
    LINK: 'Link zum Projekt',
  },
  DESCRIPTION: {
    HEADING: 'Beschreibung',
  },
  ROLES: {
    HEADING: 'Mein Rollen in diesem Projekt',
  },
  TECHNOLOGIES: {
    HEADING: 'Technologien, die das Projekt nutzt'
  },
  FOOTER: {
    DISCLAIMER: 'Disclaimer: Die Darstellung der Projektinformationen ist, soweit möglich, mit den Projektverantwortlichen abgestimmt. Dies war nicht in allen Fällen umfassend möglich. Sollten Sie der Verwendung einzelner Inhalte widersprechen wollen, setzen Sie sich bitte mit mir in Verbindung - ich entferne die betreffenden Inhalte umgehend.'
  }
};
