export default {
  /**
   * enables debug mode
   * @var {boolean}
   */
  debug: import.meta.env.DEV || false,

  i18n: {
    /**
     * enables auto-selection of used locale by browser data
     * @var {boolean}
     */
    localeAutoSelect: Boolean(Number(import.meta.env.MIDTONES_I18N_AUTO_SELECT)) || false,

    /**
     * default locale for vue-i18n module
     * @var {string}
     */
    locale: import.meta.env.MIDTONES_I18N_LOCALE || 'de',

    /**
     * fallback locale for vue-i18n module
     * @var {string}
     */
    fallbackLocale: import.meta.env.MIDTONES_I18N_FALLBACK_LOCALE || 'de',
  },

  metaData: {
    /**
     * prefix for page titles
     * @note will be translated by i18n plugin
     * @var {string}
     */
    titlePrefix: import.meta.env.MIDTONES_META_TITLE_PREFIX || 'META.TITLE.PREFIX',

    /**
     * suffix for page titles
     * @note will be translated by i18n plugin
     * @var {string}
     */
    titleSuffix: import.meta.env.MIDTONES_META_TITLE_SUFFIX || 'META.TITLE.SUFFIX',

    /**
     * default page title
     * @note will be translated by i18n plugin
     * @var {string}
     */
    defaultTitle: 'META.TITLE.DEFAULT',

    /**
     * default page description
     * @note will be translated by i18n plugin
     * @var {string}
     */
    defaultDescription: 'META.DESCRIPTION.DEFAULT',

    /**
     * default open-graph page title
     * @note will be translated by i18n plugin
     * @var {string}
     */
    defaultOgTitle: 'META.TITLE.DEFAULT',

    /**
     * default open-graph page description
     * @note will be translated by i18n plugin
     * @var {string}
     */
    defaultOgDescription: 'META.DESCRIPTION.DEFAULT',

    /**
     * base url for open-graph images
     * @var {string}
     */
    imageBaseUrl: import.meta.env.MIDTONES_META_BASE_URL || 'https://midtones.de/',

    /**
     * default open-graph page image
     * @var {string}
     */
    defaultOgImage: 'social_teaser.jpg',
  },
};
