export default {
  LABELS: {
    NAME: 'Your name',
    EMAIL: 'Your E-Mail address',
    SUBJECT: 'Please give me a short subject for your message',
    MESSAGE: 'Your message',
    SEND: 'Send Message',
  },
  PLACEHOLDERS: {
    NAME: 'Name',
    EMAIL: 'E-Mail',
    SUBJECT: 'Subject',
    MESSAGE: 'Message',
  },
  NOTIFICATION: {
    SUCCESS: {
      HEADLINE: 'Message sent',
      BODY: 'Your message has been sent and I will get in contact with you as soon as possible. Thank you.'
    },
    ERROR: {
      HEADLINE: 'Message could not be sent',
      BODY: 'An error occured while trying to relay your message. Please send me a direct E-Mail with the address on the homepage or try again a bit later.'
    }
  }
};
