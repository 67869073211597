<template>

  <header class="pointer-events-none relative z-50 flex flex-none flex-col">

    <div class="top-0 z-10 h-16 pt-6">
      <div class="sm:px-8 w-full">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="relative flex gap-4">
                <div class="flex flex-1"></div>
                <div class="flex flex-1 justify-end md:justify-center">
                  <nav class="pointer-events-auto">
                    <ul class="flex flex-1 rounded-full bg-white/90 px-3 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur">

                      <li>
                        <router-link to="/" class="relative block px-3 py-2 transition hover:text-teal-500">
                          {{ $t('NAVIGATION.NAVITEMS.HOME') }}
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/services" class="relative block px-3 py-2 transition hover:text-teal-500">
                          {{ $t('NAVIGATION.NAVITEMS.SERVICES') }}
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/projects" class="relative block px-3 py-2 transition hover:text-teal-500">
                          {{ $t('NAVIGATION.NAVITEMS.PROJECTS') }}
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/contact" class="relative block px-3 py-2 transition hover:text-teal-500">
                          {{ $t('NAVIGATION.NAVITEMS.CONTACT') }}
                        </router-link>
                      </li>
                      <li>
                        <a class="relative block px-3 py-2 transition hover:text-teal-500" href="https://gitlab.midtones.de" target="_blank">
                          {{ $t('NAVIGATION.NAVITEMS.GITLAB') }}
                        </a>
                      </li>
                      <li class="flex flex-1 border-l-2 pl-4">
                        <span class="fi fi-de px-2 py-2" v-if="currentLocale === 'de'"></span>
                        <span class="fi fi-gb px-2 py-2" v-if="currentLocale === 'en'"></span>
                        <a class="relative block px-2 py-2 transition hover:text-teal-500" id="switcherToggle" href="#" @click.prevent="toggleSwitcher">
                          <i class="fa-solid fa-chevron-down"></i>
                        </a>
                        <div class="absolute right-0 top-10 z-50 w-32 origin-top-right rounded-lg bg-white/90 px-3 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur focus:outline-none py-2" :class="{ hidden: switcherHidden }" aria-orientation="vertical" tabindex="-1">
                          <a class="leading-6 mb-2 block transition hover:text-teal-500" id="switchToGerman" href="#" @click.prevent="switchLanguage('de')">
                            <span class="fi fi-de mr-2"></span>
                            {{ $t('NAVIGATION.LOCALES.GERMAN') }}
                          </a>
                          <a class="leading-6 block transition hover:text-teal-500" id="switchToEnglish" href="#" @click.prevent="switchLanguage('en')">
                            <span class="fi fi-gb mr-2"></span>
                            {{ $t('NAVIGATION.LOCALES.ENGLISH') }}
                          </a>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div class="flex justify-end md:flex-1">
                  <router-link to="/">
                    <img alt="midtones.de" src="/images/midtones_klein.png" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>



</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { getCurrentLocale, setLocale, getAvailableLocales } from '@/plugins/i18n';

  import EventBus from '@/services/EventBus';

  export default defineComponent({
    name: 'PageHeader',
    setup() {
      return {
        currentLocale: ref<string>('de'),
        switcherHidden: ref<boolean>(true),
      };
    },
    mounted() {
        this.currentLocale = getCurrentLocale();
    },
    methods: {
      toggleSwitcher(): void {
        this.switcherHidden = !this.switcherHidden;
      },
      switchLanguage(newLocale: string):  void {
        if (getAvailableLocales().includes(newLocale)) {
          setLocale(newLocale);
          this.currentLocale = newLocale;
          this.switcherHidden = true;
          EventBus.emit(EventBus.keys.SWITCH_LOCALE, true);
        }
      }
    }
  });
</script>
