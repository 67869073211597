import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  // public routes
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Homepage.vue'),
    meta: {
      title: 'HOME.META.TITLE',
      ogTitle: 'HOME.META.TITLE',
      analyticsIgnore: true,
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/views/Services.vue'),
    meta: {
      title: 'SERVICES.META.TITLE',
      ogTitle: 'SERVICES.META.TITLE',
      analyticsIgnore: true,
    },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/views/Projects.vue'),
    meta: {
      title: 'PROJECTS.META.TITLE',
      ogTitle: 'PROJECTS.META.TITLE',
      analyticsIgnore: true,
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
    meta: {
      title: 'CONTACT.META.TITLE',
      ogTitle: 'CONTACT.META.TITLE',
      analyticsIgnore: true,
    },
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import('@/views/Imprint.vue'),
    meta: {
      title: 'IMPRINT.META.TITLE',
      ogTitle: 'IMPRINT.META.TITLE',
      analyticsIgnore: true,
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue'),
    meta: {
      title: 'PRIVACY.META.TITLE',
      ogTitle: 'PRIVACY.META.TITLE',
      analyticsIgnore: true,
    },
  },
  // fallback route for 404 error
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/PageNotFound.vue'),
    meta: {
      title: 'PAGE_NOT_FOUND.META.TITLE',
      ogTitle: 'PAGE_NOT_FOUND.META.TITLE',
      analyticsIgnore: true,
    },
  }
];

export default routes;
