export default {
  NAVITEMS: {
    HOME: 'Home',
    SERVICES: 'Services',
    PROJECTS: 'Projekte',
    CONTACT: 'Kontakt',
    GITLAB: 'Gitlab',
  },
  LOCALES: {
    GERMAN: 'Deutsch',
    ENGLISH: 'English'
  }
};
